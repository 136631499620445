$(function () {
	$('.like-accordeon').on('click', function () {
		if ($('.like-accordeon').hasClass('is-hide')) {
			$('.table-scoring .to-toggle').removeClass('hide');
			$('.like-accordeon').removeClass('is-hide');
		}
		else {
			$('.table-scoring .to-toggle').addClass('hide');
			$('.like-accordeon').addClass('is-hide');
		}
	});
	if ($('.like-accordeon').hasClass('is-hide')) {
		$('.table-scoring .to-toggle').removeClass('hide');
		$('.like-accordeon').removeClass('is-hide');
	} else {
		$('.table-scoring .to-toggle').addClass('hide');
		$('.like-accordeon').addClass('is-hide');
	}
});