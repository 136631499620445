$(document).ready(function () {

    $('.main-image-slider').slick({
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.main-image-slider-nav'
    });

    $('.main-image-slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.main-image-slider',
        dots: false,
        centerMode: false,
        arrows: false,
        focusOnSelect: true,
        responsive: [{
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }]
    });
    $(document).on('click', '[data-toggle="lightbox"]', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

    $(document).on('click', '#fullscreen', function (event) {
        event.preventDefault();
        $('.slick-current a[data-toggle="lightbox"]').ekkoLightbox();
    });
});